"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var constants_1 = require("./constants");
var controllers_1 = require("./controllers");
(function () {
    var raven;
    var context = {};
    function initAppForPage(_a, _b, _w, services) {
        var instance = _a.instance;
        var pubSub = _b.pubSub;
        raven = services && services.monitoring && services.monitoring.createMonitor(constants_1.SENTRY_DSN);
        var url = _.get(wix, 'location.baseUrl');
        raven && raven.setUserContext({
            id: url,
            url: url
        });
        context._instance = instance;
        context.pubSub = pubSub;
        return Promise.resolve();
    }
    var reportError = context.reportError = function (e) {
        raven && raven.captureException(e);
        throw e;
    };
    var wrapExportsRaven = utils_1.wrapExports(reportError);
    var controllerByType = {
        '1380bbc4-1485-9d44-4616-92e36b1ead6b': controllers_1.cartWidgetController,
        '13a94f09-2766-3c40-4a32-8edb5acdd8bc': controllers_1.productPageController
    };
    function createControllers(controllerConfigs) {
        return controllerConfigs
            .map(function (_a) {
            var type = _a.type, compId = _a.compId;
            return controllerByType[type] ?
                Promise.resolve(wrapExportsRaven(controllerByType[type](context, compId))) :
                Promise.resolve(controllers_1.emptyController(context, compId));
        });
    }
    module.exports = wrapExportsRaven({
        initAppForPage: initAppForPage,
        createControllers: createControllers,
        exports: {
            setAddToCart: function (cb) {
                context.addToCart = cb;
            },
            setGetProduct: function () {
                throw new Error('Deprecated');
            }
        }
    });
})();
